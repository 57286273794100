body{
  background: rgb(255,251,247);
  background: linear-gradient(180deg, rgba(255,251,247,1) 0%, rgba(255,251,247,1) 50%, rgba(255,255,255,1) 100%);
}
/*****************block-apropos************/
.block-apropos{
  margin: 150px 0 50px;
  background: rgb(255,255,255);
  background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,251,247,1) 100%);
  border-radius: 80px;
  padding: 40px;
}
.block-apropos span{
  font-size: 11px;
  color: #ED874C;
  font-family: Quicksand-Regular;
  padding-bottom: 6px;
  text-transform: uppercase;
}
.block-apropos h2{
  font-size: 35px;
  color: #000000;
  font-family: Quicksand-Medium;
  padding-bottom: 25px;
}
.block-apropos h2 span{
  font-size: 35px;
  color: #ED5F11;
  font-family: Quicksand-Medium;
  text-transform: lowercase;
  padding-bottom: 0;
}
.block-apropos p{
  font-size: 18px;
  color: #000000;
  font-family: Quicksand-Regular;
}
.block-apropos p strong{
  font-family: Quicksand-Bold;
}
/**********block-nais-netisse****************/
.block-nais-netisse{
  margin-bottom: 50px;
}
.block-nais-netisse span{
  font-size: 11px;
  color: #ED874C;
  font-family: Quicksand-Regular;
  padding-bottom: 6px;
  text-transform: uppercase;
}
.block-nais-netisse h2{
  font-size: 35px;
  color: #000000;
  font-family: Quicksand-Medium;
  padding-bottom: 25px;
}
.block-nais-netisse h2 span{
  font-size: 35px;
  color: #ED5F11;
  font-family: Quicksand-Medium;
  text-transform: lowercase;
  padding-bottom: 0;
}
.block-nais-netisse p{
  font-size: 18px;
  color: #000000;
  font-family: Quicksand-Regular;
}
.block-nais-netisse p strong{
  font-family: Quicksand-Bold;
}
.list-but {
  display: flex;
  align-items: center;
  padding-bottom: 30px;
}
.list-but img{
padding-right: 20px;
}
.list-but p{
  margin-bottom: 0;
}
/**********block-techno-netisse****************/
.block-techno-netisse{
  margin-bottom: 20px;
}
.block-techno-netisse span{
  font-size: 11px;
  color: #ED874C;
  font-family: Quicksand-Regular;
  padding-bottom: 6px;
  text-transform: uppercase;
}
.block-techno-netisse h2{
  font-size: 35px;
  color: #000000;
  font-family: Quicksand-Medium;
  padding-bottom: 25px;
}
.block-techno-netisse h2 span{
  font-size: 35px;
  color: #ED5F11;
  font-family: Quicksand-Medium;
  text-transform: lowercase;
  padding-bottom: 0;
}
.block-techno-netisse p{
  font-size: 18px;
  color: #000000;
  font-family: Quicksand-Regular;
}
.block-text-img{
  position:relative;
  margin-bottom: 20px;
}
.block-text-img::before{
  content: '';
  background: linear-gradient(180deg, rgba(255,240,232,1) 0%, rgba(255,255,255,0) 100%);
  border-radius: 80px;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 80%;
  top: 60%;
  left: 50%;
  transform: translate(-50%,-50%);
}

/*****************block-croiss-netisse**********/
.block-croiss-netisse{
  background:  url(./../../../public/images/Fichier.png) , linear-gradient(200deg, rgba(237,95,17,1) 0%, rgba(229,114,114,1) 70%);
  border-radius: 80px;
  padding: 40px;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-bottom: 50px;
}
.block-croiss-netisse span{
  font-size: 11px;
  color: #fff;
  font-family: Quicksand-Regular;
  padding-bottom: 6px;
  text-transform: uppercase;
}
.block-croiss-netisse h2{
  font-size: 35px;
  color: #fff;
  font-family: Quicksand-Medium;
  padding-bottom: 20px;
}
.block-croiss-netisse p{
  font-size: 18px;
  color: #fff;
  font-family: Quicksand-Regular;
}
/*******************block-team-netisse************/
.block-team-netisse{
  margin-bottom: 50px;
}
.block-colab{
  padding: 40px;
}
.block-team-netisse span{
  font-size: 11px;
  color: #ED874C;
  font-family: Quicksand-Regular;
  padding-bottom: 6px;
  text-transform: uppercase;
}
.team-description{
  padding-top: 50px;
}
.block-team-netisse h2{
  font-size: 35px;
  color: #000000;
  font-family: Quicksand-Medium;
  padding-bottom: 25px;
}
.block-team-netisse h2 span{
  font-size: 35px;
  color: #ED5F11;
  font-family: Quicksand-Medium;
  text-transform: inherit;
  padding-bottom: 0;
}
.block-team-netisse p{
  font-size: 18px;
  color: #000000;
  font-family: Quicksand-Regular;
}
.block-team-netisse p span{
  font-size: 18px;
  color: #000000;
  font-family: Quicksand-Bold;
  text-transform: initial;
}
.block-team-netisse h3{
  font-size: 30px;
  color: #000000;
  font-family: Quicksand-Medium;
  padding-bottom: 30px;
}
.block-team-netisse h3 span{
  font-size: 30px;
  color: #ED5F11;
  font-family: Quicksand-Medium;
  text-transform: inherit;
  padding-bottom: 0;
}
.block-team-dirig h3, .block-team-netisse h3 {
  padding-left: 40px;
}
.team-dirig{
  border-radius: 60px;
  position: relative;
  padding: 40px 40px 0;
  margin-bottom: 50px;
  align-items: start;
}
.img-team-1{
  height: 160px;
  width: 135px;
  /*background-image: url(./../../../public/images/profile-pic_10.png);*/
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 40px;
  box-shadow: 19px 19px 43px 0px rgba(0,0,0,0.10);
  -webkit-box-shadow: 19px 19px 43px 0px rgba(0,0,0,0.10);
  -moz-box-shadow: 19px 19px 43px 0px rgba(0,0,0,0.10);
  margin: 0px auto 30px;
}
.img-team-2{
  height: 160px;
  width: 135px;
  /*background-image: url(./../../../public/images/profile-pic_9.png);*/
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 40px;
  box-shadow: 19px 19px 43px 0px rgba(0,0,0,0.10);
  -webkit-box-shadow: 19px 19px 43px 0px rgba(0,0,0,0.10);
  -moz-box-shadow: 19px 19px 43px 0px rgba(0,0,0,0.10);
  margin: 0px auto 30px;
}
.img-team-3{
  height: 160px;
  width: 135px;
  /*background-image: url(./../../../public/images/profile-pic_11.png);*/
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 40px;
  box-shadow: 19px 19px 43px 0px rgba(0,0,0,0.10);
  -webkit-box-shadow: 19px 19px 43px 0px rgba(0,0,0,0.10);
  -moz-box-shadow: 19px 19px 43px 0px rgba(0,0,0,0.10);
  margin: 0px auto 30px;
}
.team-title-photo .name{
  margin-bottom: 0;
  font-family: Quicksand-Bold;
  font-size: 20px;
}
.team-title-photo .title{
  font-family: Quicksand-Medium;
  margin-bottom: 30px;
  font-size: 18px;
}
.quote p, p.quote{
  color: #E86012 !important;
}
.team-dirig::before{
  content: '';
  background: rgb(255,240,232);
  background: linear-gradient(180deg, (255, 217, 200, 1) 0%, rgba(255,255,255,0) 100%);
  border-radius: 80px;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 70px;
  left: 0;
  opacity: 0.23;
}
 
@media screen and (max-width: 991px){
  .team-description {
    padding-top: 30px;
  }
}
.no_padding_bt {
  margin-bottom: -33px;
}

@media (max-width: 991px) {
  .no_padding_bt {
    margin-bottom: -31px;
  }
} 
@media screen and (max-width: 991px){
  .team-description {
    padding-top: 30px;
  }
}
.img_under {
  margin-top: 33px;
  margin-bottom: -33px;
}